import * as Sentry from '@sentry/browser';


// same DSN like production, but without http passowrd, could also be injected like the SENTRY_RELEASE
Sentry.init({
  dsn: 'https://da744f5729cc4786ade55746f55c321f@o1129002.ingest.sentry.io/6172415', environment: 'production',
  ignoreErrors: [
    "Non-Error promise rejection captured",
  ]
});
// to allow easy access from all areas append to window:
window.Sentry = Sentry
